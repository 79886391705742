.app button {
  font-family: "SupremeLLWebTrial-Bold";
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: #222;
  border-radius: 20px;
  padding: 1em 2.5em;
  color: #fff;
  border: none;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: row;
  margin-bottom: 5vh;
  gap: 0.1rem;
}

.scale h1 {
  font-family: "SupremeLLWebTrial-Bold";
  text-transform: uppercase;
}

.item {
  --size: 1;

  font-size: calc(var(--size) * var(--base-size));
}

