
@font-face {
  font-family: "SupremeLLWebTrial-Book";
  src: url("/public/fonts/SupremeLLWebTrial-Book.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Black";
  src: url("/public/fonts/SupremeLLWebTrial-Black.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BoldItalic";
  src: url("/public/fonts/SupremeLLWebTrial-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Thin";
  src: url("/public/fonts/SupremeLLWebTrial-Thin.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Medium";
  src: url("/public/fonts/SupremeLLWebTrial-Medium.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-ThinItalic";
  src: url("/public/fonts/SupremeLLWebTrial-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Light";
  src: url("/public/fonts/SupremeLLWebTrial-Light.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-MediumItalic";
  src: url("/public/fonts/SupremeLLWebTrial-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BookItalic";
  src: url("/public/fonts/SupremeLLWebTrial-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BlackItalic";
  src: url("/public/fonts/SupremeLLWebTrial-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Regular";
  src: url("/public/fonts/SupremeLLWebTrial-Regular.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BoldFlat";
  src: url("/public/fonts/SupremeLLWebTrial-BoldFlat.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-LightItalic";
  src: url("/public/fonts/SupremeLLWebTrial-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BoldFlatItalic";
  src: url("/public/fonts/SupremeLLWebTrial-BoldFlatItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BoldFlatItalic";
  src: url("/public/fonts/SupremeLLWebTrial-BoldFlatItalic.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-BoldFlat";
  src: url("/public/fonts/SupremeLLWebTrial-BoldFlat.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Bold";
  src: url("/public/fonts/SupremeLLWebTrial-Bold.woff") format("woff");
}

@font-face {
  font-family: "SupremeLLWebTrial-Italic";
  src: url("/public/fonts/SupremeLLWebTrial-Italic.woff") format("woff");
}

:root {
  --base-size: 12px;
}

body {
  margin: 0;
  padding: 25px 50px;
  font-size: var(--base-size);
  font-family: "SupremeLLWebTrial-Book", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: "#fafafa";
}

h1, h2, h3, h4 {
  padding: 0;
  line-height: 1em;
}
